import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { fetchAllDataAPI, fetchAllMemberDataAPI, filterByParams, orderActionAPI } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye, FaRegHandPointRight } from 'react-icons/fa';
import { Badge, Button, Col, Form, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import { numberFormat } from '../components/numberFormat';
import { FiRefreshCcw } from "react-icons/fi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { MdFilterTiltShift } from 'react-icons/md';
import Swal from 'sweetalert2'

const AllOrder = () => {
    const [memberorder, setMemberOrder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const memberid = localStorage.getItem("memberdata");
    const navigate = useNavigate();
    const [filtervalues, setFilterValue] = useState();
    const [filtervalidated, setFilterValidated] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 50;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = memberorder.slice(firstIndex, lastIndex);
    const npage = Math.ceil(memberorder.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)
    const [filterstate, setFilterState] = useState([]);
    const [filterorder, setFilterOrder] = useState([]);
    const [filterdelivery, setFilterDelivery] = useState([]);
    const [checkvalues, setCheckedValue] = useState([]);

    useEffect(() => {
        if (memberid) {
            navigate('/all-orders');
            getAllMemberCreatedOrder(memberid);
            getFilterStateDeliveryOrder();
            setCheckedValue("");
        }
        else {
            navigate("/");
        }
    }, [memberid]);

    const getFilterStateDeliveryOrder = () => {
        fetchAllDataAPI('member-get-state-delivery-order-filter-data').then((res) => {
            if (res.data.status === 1) {
                setFilterState(...[res.data.state])
                setFilterOrder(...[res.data.ostatus])
                setFilterDelivery(...[res.data.dstatus])
            }
        })
    }
    const getAllMemberCreatedOrder = (memberid) => {
        setIsloading(true);
        fetchAllMemberDataAPI('member-get-all-agent-order-data', memberid).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setMemberOrder(...[res.data.order]);
            }
            else {
                setIsloading(false);
                setMemberOrder("");
            }
        })
    }

    const handleOrderFilter = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const filterdata = {
                    member_id: memberid,
                    f_order_id: filtervalues.f_order_id,
                    f_order_mobile: filtervalues.f_order_mobile,
                    f_order_awb: filtervalues.f_order_awb,
                    f_order_state: filtervalues.f_order_state,
                    f_order_sdate: filtervalues.f_order_sdate,
                    f_order_edate: filtervalues.f_order_edate,
                    f_order_dstatus: filtervalues.f_order_dstatus,
                    f_order_ostate: filtervalues.f_order_ostate,
                }
                filterByParams('member-get-after-order-filter-data', filterdata).then((res) => {
                    if (res.data.status === 1) {
                        setMemberOrder(...[res.data.order]);
                    }
                    else {
                        setMemberOrder("");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setFilterValidated(true);
    };
    let handleFilterInputs = e => {
        setFilterValue({
            ...filtervalues,
            [e.target.name]: e.target.value,
        });
    };
    const refreshOrderButton = () => {
        getAllMemberCreatedOrder(memberid)
    }
    const handleCheckbox = (e) => {
        let isSelected = e.target.checked;
        const { name, checked } = e.target;
        let value = parseInt(e.target.value);

        if (name === "allSelect") {
            let temOrder = records.map(record => { return { ...record, isChecked: checked } });
            setMemberOrder(temOrder);
            if (records.length === checkvalues.length) {
                setCheckedValue([]);
            }
            else {
                const postid = records.map((item) => {
                    return item.crm_order_id;
                })
                setCheckedValue(postid);
            }
        }
        else {
            let temOrder = records.map(record => record.crm_order_id === name ? { ...record, isChecked: checked } : record);
            setMemberOrder(temOrder);
            if (isSelected) {
                setCheckedValue([...checkvalues, value]);
            }
            else {
                setCheckedValue((prevData) => {
                    return prevData.filter((id) => {
                        return id != value;
                    })
                })
            }
        }
    };
    const handleActionBtn = (e) => {
        if (checkvalues.length === 0) {
            toast.warning("Kindly select the order at least one");
        }
        else {
            const actionname = e.target.value;
            if (actionname != "") {
                const data = {
                    checkvalues: checkvalues.toString(),
                    actionname: e.target.value
                };
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'danger',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: `Yes, ${actionname} it!`,
                    cancelButtonText: 'No, cancel!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        orderActionAPI('member-make-changes-order-status', data).then((res) => {
                            if (res.data.status === 1) {
                                setCheckedValue("");
                                Swal.fire(
                                    `${actionname}`,
                                    `order status has been changed to ${actionname}.`,
                                    'success'
                                )
                                getAllMemberCreatedOrder(memberid);
                            }
                        })
                    }
                    else if (
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        Swal.fire(
                            'Cancelled',
                            'Your imaginary file is safe :)',
                            'error'
                        )
                    }
                })
            }
            else{
                toast.warning("Kindly select the order status");
            }
        }
    }
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:MEMBER::ALL ORDERS</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center justify-content-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All ORDERS</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <MdFilterTiltShift className="formIcons" />
                                        <h5 className="mb-0 text-danger">Order Filter</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={filtervalidated} onSubmit={handleOrderFilter} method="GET">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Order ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search By Order ID"
                                                name="f_order_id"
                                                onChange={handleFilterInputs}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search By Mobile Number"
                                                name="f_order_mobile"
                                                onChange={handleFilterInputs}
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>AWB Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search By AWB Number"
                                                name="f_order_awb"
                                                onChange={handleFilterInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid customer mobile number
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom10">
                                            <Form.Label>Search By State</Form.Label>
                                            <Form.Select
                                                placeholder="Gender Name"
                                                name="f_order_state"
                                                onChange={handleFilterInputs}>
                                                <option value="">Select State</option>
                                                {filterstate?.map((filterstate, index) => (
                                                    <option key={index} value={filterstate?.state_name}>{filterstate?.state_name}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Start Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                placeholder="Enter the Mobile Number"
                                                name="f_order_sdate"
                                                onChange={handleFilterInputs}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                placeholder="Enter the Mobile Number"
                                                name="f_order_edate"
                                                onChange={handleFilterInputs}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom10">
                                            <Form.Label>Search By Delivery Status</Form.Label>
                                            <Form.Select
                                                name="f_order_dstatus"
                                                onChange={handleFilterInputs}>
                                                <option value="">Select Delivery Status</option>
                                                {filterdelivery?.map((filterdelivery, index) => (
                                                    <option key={index} value={filterdelivery?.dstatus_name}>{filterdelivery?.dstatus_name}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom10">
                                            <Form.Label>Search By Order Status</Form.Label>
                                            <Form.Select
                                                name="f_order_ostate"
                                                onChange={handleFilterInputs}>
                                                <option value="">Select Order Status</option>
                                                {filterorder?.map((filterorder, index) => (
                                                    <option key={index} value={filterorder?.ostatus_name}>{filterorder?.ostatus_name}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-2" style={{ marginTop: "36px" }}>
                                        <Form.Group as={Col} md="3">
                                            <Button type="submit" className="btn btn-sm btn-primary">Submit</Button>
                                            <span style={{ marginLeft: "10px", cursor: "pointer" }} onClick={refreshOrderButton} className="btn-sm btn-secondary "><FiRefreshCcw /> Refresh</span>
                                        </Form.Group>
                                        <Col md={2}>
                                            <div className="d-flex">
                                                <Form.Label style={{ marginRight: "5px" }}>Action</Form.Label>
                                                <Form.Select
                                                    size='md'
                                                    onChange={handleActionBtn}
                                                >
                                                    <option value="">Select Action</option>
                                                    <option value="Hold">Hold</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Draft">Draft</option>
                                                    <option value="Cancelled">Cancelled</option>
                                                    <option value="Confirmed">Confirmed</option>
                                                    <option value="Not Confirmed">Not Confirmed</option>
                                                </Form.Select>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>

                    <div className="card tablecard">
                        <div className="table-responsive">
                            <Table className="table-sm" responsive="sm" striped bordered hover>
                                <thead>
                                    <tr >
                                        <th>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="allSelect"
                                                onChange={handleCheckbox}
                                                checked={checkvalues.length === records.length || false}
                                            />Select All
                                        </th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Adv Amt</th>
                                        <th>Balance Amt</th>
                                        <th>Total Price</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>Language</th>
                                        <th>Agent Name</th>
                                        <th>Delivery Status</th>
                                        <th>Order Status</th>
                                        <th>Confirm TO</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <>
                                            <tr>
                                                {Array.from({ length: 17 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 17 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 17 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 17 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 17 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 17 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 17 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 17 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 17 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                            <tr>
                                                {Array.from({ length: 17 }).map((_, index) => (
                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                ))}
                                            </tr>
                                        </>
                                        :
                                        records ?
                                            records?.map((records, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name={records?.crm_order_id}
                                                            value={records?.crm_order_id}
                                                            checked={records?.isChecked || false}
                                                            onChange={handleCheckbox}
                                                        />
                                                        {index + 1}
                                                    </td>
                                                    <td><Badge bg="success">{records?.order_number}</Badge></td>
                                                    <td data-tooltip-id={`my-tooltip-${index}`}>{records?.order_name}
                                                        <ReactTooltip
                                                            id={`my-tooltip-${index}`}
                                                            place="bottom"
                                                            content={records?.order_address}
                                                        />
                                                    </td>
                                                    <td>{records?.order_mobile}</td>
                                                    <td>{records?.order_state}</td>
                                                    <td>{records?.order_city}</td>
                                                    <td><Badge bg="warning">{numberFormat(records?.order_advance_amt)}</Badge></td>
                                                    <td><Badge bg="info">{numberFormat(records?.total_balance)}</Badge></td>
                                                    <td><Badge bg="primary">{numberFormat(records?.grand_total)}</Badge></td>
                                                    <td><Badge bg="success">{moment(records?.created_date).format('Do MMMM YYYY')}</Badge ></td>
                                                    <td>{records?.created_time}</td>
                                                    <td>{records?.order_language_name}</td>
                                                    <td>{records?.agent_name}</td>
                                                    <td><Badge bg="secondary">{records?.shipping_status}</Badge></td>
                                                    <td>{records?.order_confirm_status}</td>
                                                    <td>{records?.order_confirm_to}</td>
                                                    <td><Link target="_blank" to={`/invoice/${records.order_number}`} className="badge badge-success bg-success"><FaRegEye style={{ fontSize: "20px" }} /></Link></td>
                                                </tr>
                                            ))
                                            :
                                            <tr >
                                                <td className="text-center" colSpan={17}>Data not founds......</td>
                                            </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Adv Amt</th>
                                        <th>Balance Amt</th>
                                        <th>Total Price</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>Language</th>
                                        <th>Agent Name</th>
                                        <th>Delivery Status</th>
                                        <th>Order Status</th>
                                        <th>Confirm TO</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper >
        </div >
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default AllOrder