import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FaBorderAll, FaPlus, FaRegHandPointRight } from 'react-icons/fa';
import { addFormData, fetchAllMemberDataAPI, fetchAllDataAPI, getSingleDataFromDatabase, fetchFilterByMobileDataAPI, filterByDataParams } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye } from 'react-icons/fa';
import { Badge, Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { numberFormat } from '../components/numberFormat';
import { Tooltip as ReactTooltip } from "react-tooltip";


const CallFollowUp = () => {
    const [validated, setValidated] = useState(false);
    const memberid = localStorage.getItem('memberdata');
    const navigate = useNavigate();
    const [values, setFollowUpValue] = useState();
    const [memberorder, setMemberOrder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [filtermobile, setFilterMobile] = useState(false);
    const [followupm, setFollowUp] = useState([]);
    const [followname, setFollowName] = useState([]);
    const [problem, setProblem] = useState([]);
    const [problemname, setProblemName] = useState("");
    const [subpmname, setSubProblemName] = useState([]);
    const [filtervalidated, setFilterValidated] = useState(false);
    const [filtervalues, setFilterValue] = useState();
    const [agentlist, setAgentList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 50;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = followupm.slice(firstIndex, lastIndex);
    const npage = Math.ceil(followupm.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    useEffect(() => {
        if (memberid) {
            navigate('/call-follow-up');
            getAllCallFollowUp(memberid)
            getCallFollowUpName();
            getAllAgentOwnCenter(memberid)
        }
        else {
            navigate("/");
        }
    }, [memberid]);

    const getAllCallFollowUp = (memberid) => {
        setIsloading(true);
        fetchAllMemberDataAPI('member-get-call-follow-up-from-agent', memberid).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setFollowUp(...[res.data.followup]);
            }
            else {
                setIsloading(false);
                toast.warning("Check your connection")
            }
        })
            .catch((error) => {
                setIsloading(false);
                setFollowUp("");
            });
    }

    const getCallFollowUpName = () => {
        fetchAllDataAPI('member-get-all-call-follow-up-name').then((res) => {
            if (res.data.status === 1) {
                setFollowName(...[res.data.follow])
                setProblem(...[res.data.problem])
            }
            else {
                toast.warning("Check your connection")
            }
        })
            .catch((error) => {
                setProblem("")
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const data = {
                    center_mis_id: memberid,
                    followup_name: values.followup_name,
                    followup_mobile: values.followup_mobile,
                    followup_age: values.followup_age,
                    followup_gender: values.followup_gender,
                    follow_problem: problemname,
                    follow_subproblem: values.follow_subproblem,
                    call_remarks: values.call_remarks,
                    followup_notes: values.followup_notes,
                    followup_city: values.followup_city,
                    agent_id: values.agent_id
                }
                addFormData('member-create-call-follow-up-from-member', data).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Follow up has been created successfully!");
                        if (values.call_remarks === "10") {
                            localStorage.setItem("followmobile", res.data.fmobile);
                            navigate('/member-new-order');
                        }
                        else {
                            setFilterMobile(false);
                            setMemberOrder(false);
                            getAllCallFollowUp(memberid);
                            setValidated(false);
                            event.target.reset();
                        }
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setValidated(true);
    };
    const handleSearchByMobile = async (e) => {
        if (e.target.value.length === 10) {
            setIsloading(true)
            const filtervalue = e.target.value
            setFilterMobile(...[e.target.value])
            e.preventDefault();
            if (filtervalue) {
                fetchFilterByMobileDataAPI('agent-get-filter-order-by-mobile-number-data', filtervalue).then((res) => {
                    if (res.data.status === 1) {
                        setIsloading(false)
                        setMemberOrder(...[res.data.order]);
                    }
                    else {
                        setMemberOrder(false);
                        setIsloading(false)
                    }
                })
            }
            else {
                setIsloading(true)
            }
        }
    };

    let handleInputs = e => {
        setFollowUpValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleProblem = (e) => {
        setProblemName(e.target.value)
        const problemid = e.target.value;
        getSingleDataFromDatabase(`member-get-sub-problem-by-id/${problemid}`).then((res) => {
            if (res.data.status === 1) {
                setSubProblemName(...[res.data.subproblem])
            }
        })
    }
    const handleCallFollowFilter = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                filterByDataParams('member-get-call-follow-filter-by-params', filtervalues).then((res) => {
                    if (res.data.status === 1) {
                        setFollowUp(...[res.data.followup]);
                    }
                    else {
                        setFollowUp("");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setFilterValidated(true);
    };
    let handleFilterInputs = e => {
        setFilterValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const getAllAgentOwnCenter = (memberid) => {
        fetchAllMemberDataAPI('member-get-all-agent-own-center', memberid).then((res) => {
            if (res.data.status === 1) {
                setAgentList(...[res.data.agentdata]);
            }
        })
            .catch((error) => {
                setIsloading(false);
            });
    }
    const handleResetBtn = () => {
        setFilterMobile(false);
        setMemberOrder(false);
        getAllCallFollowUp(memberid);
    }
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:MEEMBER::CALL FOLLOW UP</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb btnbreadcrum d-none d-sm-flex align-items-center justify-between">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">Call follow up</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <FaBorderAll className="formIcons" />
                                        <h5 className="mb-0 text-danger">ADD Call follow up</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={validated} onSubmit={handleSubmit} method="POST">
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Customer Name"
                                                name="followup_name"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid customer name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom02">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter the Mobile Number"
                                                name="followup_mobile"
                                                onChange={handleInputs}
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                                onKeyUp={handleSearchByMobile}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid customer mobile number
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom03">
                                            <Form.Label>Age</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the Age"
                                                name="followup_age"
                                                onChange={handleInputs}
                                                pattern="[0-9]*"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid age
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                                            <Form.Label>City</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter the City"
                                                name="followup_city"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid age
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom04">
                                            <Form.Label>Gender</Form.Label>
                                            <Form.Select
                                                placeholder="Gender Name"
                                                name="followup_gender"
                                                onChange={handleInputs}>
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Others">Others</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid age
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom06">
                                            <Form.Label>Select Problem</Form.Label>
                                            <Form.Select required
                                                placeholder="Problem Name"
                                                name="follow_problem"
                                                onChange={handleProblem}
                                            >
                                                <option value="">Select Problem</option>
                                                {problem?.map((problem, index) => (
                                                    <option key={index} value={problem?.problem_id}>{problem?.problem_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please select a valid Remarks
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom07">
                                            <Form.Label>Select Problem Type</Form.Label>
                                            <Form.Select required
                                                placeholder="Problem Name"
                                                name="follow_subproblem"
                                                onChange={handleInputs}>
                                                <option value="">Select Problem</option>
                                                {subpmname?.map((subpmname, index) => (
                                                    <option key={index} value={subpmname?.sproblem_id}>{subpmname?.sproblem_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please select a valid Remarks
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom08">
                                            <Form.Label>Select Remarks</Form.Label>
                                            <Form.Select required
                                                placeholder="Category Name"
                                                name="call_remarks"
                                                onChange={handleInputs}>
                                                <option value="">Select Remarks</option>
                                                {followname?.map((followname, index) => (
                                                    <option key={index} value={followname?.followupid}>{followname?.followupname}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please select a valid Remarks
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom10">
                                            <Form.Label>Call Type</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Gender Name"
                                                name="followup_call_type"
                                                onChange={handleInputs}>
                                                <option value="">Select Call Type</option>
                                                <option value="Inbound">Inbound</option>
                                                <option value="Outbound">Outbound</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid call type
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" controlId="validationCustom10">
                                            <Form.Label>Select Agent</Form.Label>
                                            <Form.Select
                                                required
                                                placeholder="Gender Name"
                                                name="agent_id"
                                                onChange={handleInputs}>
                                                <option value="">Select Agent</option>
                                                {agentlist?.map((agentlist, index) => (
                                                    <option key={index} value={agentlist?.center_agent_id}>{agentlist?.agent_name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid call type
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="12" controlId="validationCustom09">
                                            <Form.Label>Notes</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                type="text"
                                                placeholder="Enter the Notes"
                                                name="followup_notes"
                                                onChange={handleInputs}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid notes
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" style={{ marginTop: "36px" }}>
                                            <Button type="submit" className="btn btn-sm btn-primary">Submit</Button>
                                            <span style={{ marginLeft: "5px" }} onClick={handleResetBtn} className="btn btn-sm btn-secondary">Reset</span>
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <FaBorderAll className="formIcons" />
                                        <h5 className="mb-0 text-danger">Filter Call follow up</h5>
                                    </div>
                                </div>
                                <hr />
                                <Form noValidate validated={filtervalidated} onSubmit={handleCallFollowFilter} method="POST">
                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="3" controlId="validationCustom01">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter the Mobile Number"
                                                name="followup_mobile"
                                                onChange={handleFilterInputs}
                                                pattern="[6789][0-9]{9}"
                                                maxLength={10}
                                                minLength={10}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                <FaRegHandPointRight /> Please provide a valid customer mobile number
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md="3" style={{ marginTop: "36px" }}>
                                            <Button type="submit" className="btn btn-sm btn-primary">Submit</Button>
                                        </Form.Group>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-xl-12 callfollowtable card marbottom">
                        {(() => {
                            if (filtermobile != "") {
                                return (
                                    <div className="tablecard" >
                                        <div className="table-responsive">
                                            <Table size="sm" responsive="sm" striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Order No.</th>
                                                        <th>Name</th>
                                                        <th>Mobile</th>
                                                        <th>State</th>
                                                        <th>City</th>
                                                        <th>Advance Amt</th>
                                                        <th>Balance Amt</th>
                                                        <th>Total Price</th>
                                                        <th>Order Date</th>
                                                        <th>Order Time</th>
                                                        <th>Delivery Status</th>
                                                        <th>Order Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading ?
                                                        <>
                                                            <tr>
                                                                {Array.from({ length: 14 }).map((_, index) => (
                                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {Array.from({ length: 14 }).map((_, index) => (
                                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {Array.from({ length: 14 }).map((_, index) => (
                                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {Array.from({ length: 14 }).map((_, index) => (
                                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {Array.from({ length: 14 }).map((_, index) => (
                                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {Array.from({ length: 14 }).map((_, index) => (
                                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {Array.from({ length: 14 }).map((_, index) => (
                                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {Array.from({ length: 14 }).map((_, index) => (
                                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {Array.from({ length: 14 }).map((_, index) => (
                                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {Array.from({ length: 14 }).map((_, index) => (
                                                                    <td key={index}><Skeleton animation="wave" variant="text" /></td>
                                                                ))}
                                                            </tr>
                                                        </>
                                                        :
                                                        memberorder ?
                                                            memberorder?.map((memberorder, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td><Badge bg="success">{memberorder?.order_number}</Badge></td>
                                                                    <td data-tooltip-id={`my-tooltip-${index}`}>{memberorder?.order_name}
                                                                        <ReactTooltip
                                                                            id={`my-tooltip-${index}`}
                                                                            place="bottom"
                                                                            content={memberorder?.order_address}
                                                                        />
                                                                    </td>
                                                                    <td>{memberorder?.order_mobile}</td>
                                                                    <td>{memberorder?.order_state}</td>
                                                                    <td>{memberorder?.order_city}</td>
                                                                    <td><Badge bg="warning">{numberFormat(memberorder?.order_advance_amt)}</Badge></td>
                                                                    <td><Badge bg="info">{numberFormat(memberorder?.total_balance)}</Badge></td>
                                                                    <td><Badge bg="primary">{numberFormat(memberorder?.grand_total)}</Badge></td>
                                                                    <td><Badge bg="success">{moment(memberorder?.created_date).format('Do MMMM YYYY')}</Badge ></td>
                                                                    <td>{memberorder?.created_time}</td>
                                                                    <td>{memberorder?.shipping_status}</td>
                                                                    <td>{memberorder?.order_confirm_status}</td>
                                                                    <td><Link target="_blank" to={`/invoice/${memberorder.order_number}`} className="badge bg-success badge-success"><FaRegEye style={{ fontSize: "20px" }} /></Link></td>
                                                                </tr>
                                                            ))
                                                            :
                                                            <tr >
                                                                <td colSpan={15} className="text-center">Data not founds......</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Order No.</th>
                                                        <th>Name</th>
                                                        <th>Mobile</th>
                                                        <th>State</th>
                                                        <th>City</th>
                                                        <th>Advance Amt</th>
                                                        <th>Balance Amt</th>
                                                        <th>Total Price</th>
                                                        <th>Order Date</th>
                                                        <th>Order Time</th>
                                                        <th>Delivery Status</th>
                                                        <th>Order Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </div>
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className="tablecard" >
                                        <div className="table-responsive">
                                            <Table className="table-sm" responsive="sm" striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Mobile</th>
                                                        <th>Age</th>
                                                        <th>City</th>
                                                        <th>Gender</th>
                                                        <th>Problem</th>
                                                        <th>Sub Problem</th>
                                                        <th>Remarks</th>
                                                        <th>Notes</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading ?
                                                        <>
                                                            <tr>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                                <td><Skeleton animation="wave" variant="text" /></td>
                                                            </tr>
                                                        </>
                                                        :
                                                        records ?
                                                            records?.map((records, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{records?.followup_name}</td>
                                                                    <td>{records?.followup_mobile}</td>
                                                                    <td>{records?.followup_age}</td>
                                                                    <td>{records?.followup_city}</td>
                                                                    <td>{records?.followup_gender}</td>
                                                                    <td>{records?.problem_name}</td>
                                                                    <td>{records?.sproblem_name}</td>
                                                                    <td><Badge className="badge badge-primary">{records?.followupname}</Badge></td>
                                                                    <td>{records?.followup_notes}</td>
                                                                    <td>{moment(records?.followup_date).format('Do MMMM YYYY')}</td>
                                                                    <td>{records?.followup_time}</td>
                                                                    <td><Link data-tooltip-id={`my-tooltip-${index}`} className="btncreate" to={`/add-member-order/${records?.followup_mobile}`}>
                                                                        <FaPlus />
                                                                        <ReactTooltip
                                                                            id={`my-tooltip-${index}`}
                                                                            place="bottom"
                                                                            content="Create Order"
                                                                        />
                                                                    </Link>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            :
                                                            <tr >
                                                                <td colSpan={13}><p className="text-center">Data not founds......</p></td>
                                                            </tr>
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Mobile</th>
                                                        <th>Age</th>
                                                        <th>City</th>
                                                        <th>Gender</th>
                                                        <th>Problem</th>
                                                        <th>Sub Problem</th>
                                                        <th>Remarks</th>
                                                        <th>Notes</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                            <nav>
                                                <ul className="pagination justify-content-end round-pagination">
                                                    <li className="page-item">
                                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                                    </li>
                                                    {
                                                        numbers.map((n, i) => (
                                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                                            </li>
                                                        ))
                                                    }
                                                    <li className="page-item">
                                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                )
                            }
                        })()}
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default CallFollowUp